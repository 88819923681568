@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.modal {
  width: 100%;
  min-width: 330px;
  position: relative;
  border-radius: 15px;
  background-color: #262626;
}

.content {
  @include font-defaults-new;
  display: flex;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  &.hideClose {
    padding-top: 24px;
  }
}

.heading {
  @include font-defaults-new;
  max-width: 280px;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 16px;
}

.info {
  @include font-defaults-new;
  max-width: 280px;
  font-size: 14px;
  line-height: 18px;
}

.actions {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;

  .button {
    &.cancel {
      margin-top: 12px;
      color: $color-s-error !important;
    }
  }
}
