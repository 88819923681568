@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  width: 92%;
  height: 40px;
  display: flex;
  align-items: center;
  display: flex;
  gap: 12px;
  border: 0;
  cursor: pointer;
  color: $color-gray-40;
  background-color: transparent;
}

.title {
  @include font-defaults-new;
  font-size: 14px;
  text-align: left;

  flex: 1;
  overflow: hidden;
  position: relative;
  white-space: nowrap;

  max-width: 120px; 
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-gray-20;

  &.active {
    font-weight: 500;
    color: $color-p-white;
    border-left-color: $color-p-white;
  }
}

.content {
  gap: 8px;
  display: flex;
  position: absolute;
  left: 36px;
  margin-bottom: 30px;

}

.unreadCount {
  width: 23px;
  display: flex;

  &.large {
    width: 34px;
  }

  .count {
    display: flex;
    justify-content: center;
    align-items: center;

    @include font-defaults-new;
    font-size: 12px;
    font-weight: 600;
    color: $color-gray-20;

    padding: 4px;
    width: 20px;
    height: 20px;

    border-radius: 100%;
    background: $color-gray-90;
  }
}

.tooltip {
  top: 25px;
  left: -16px;
  z-index: 9999;
  display: none !important;
  transform: translate(3px, 0);
  position: absolute !important;
  
  p {
    margin: -3px;
    overflow: hidden;
    font-weight: lighter;
    font-size: 13.7px;
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  svg {
    left: 10% !important;
  }
}

.iconTooltip {
  @include font-defaults-new;
  letter-spacing: 0.02em;
  width: max-content;

  @media (min-width: $grid-breakpoint-medium-min) {
    text-align: center;

    &:hover {
      ~ .tooltip {
        display: flex !important;
      }
    }
  }
}
.author {
  @include font-defaults-new;
  max-width: 95px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-gray-70;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.info {
  display: flex;
  flex-direction: row;
  gap: 6px;
  position: relative;
  align-items: center;

  &.disabled{
    display: none;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 120px; 
}    
