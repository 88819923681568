@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';

.overlay {
  background-color: rgb(0, 0, 0, 0.9);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 200ms linear;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: none;
  display: flex;
  place-content: center;
  transform: translate(-50%, -50%);

  .container {
    @include font-defaults-new;

    font-size: 32px;
    line-height: 40px;
    color: white;

    .closeButton {
      z-index: 1;
      position: relative;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-right: 18px;
      margin: 0 0 0 auto;
      border-width: 2px;
      border-style: dashed;
      border-color: transparent;
    }

    .closeIcon {
      color: white;
    }
  }
}
