@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.button {
  border: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  background-color: transparent;

  &.small {
    display: flex;
    justify-content: center;
  }
}

.image {
  position: relative;

  &:not(.small) {
    height: 32px;
    left: -6px;
  }

  &.small {
    height: 22px;
    margin: 5px 0 5px 0;
  }
}
