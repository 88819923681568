@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
@import '../../styles/variables.scss';


.clock {
  display: flex;
  gap: 4px;
  text-align: center;
  justify-content: center;

  .column {
    position: relative;

    &.days{
      margin-right: 10px;
    }

    &.sm {
      height: 49px
    }

    &.md {
      height: 60px;
    }
    
    &.lg {
      height: 72px;
    }
  }
}

.labelBig {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  line-height: 1;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;

  &.gray {
    color: $color-p-white-50A;
    background-color: $color-p-white-5A;
  }

  &.white {
    color: $color-gray-70;
    background-color: $color-p-white;
  }

  &.default {
    color: $color-p-white-50A;
    background-color: transparent;
  }

  &.sm {
    font-size: 24px;
    padding: 4px;
    width: 44px;
    font-weight: 500;
  }

  &.md {
    font-size: 40px;
    padding: 4px 0px 2px 0;
    width: 49px;
  }
  
  &.lg {
    font-size: 40px;
    padding: 6px;
    width: 59px;
  }
}

.labelSmall {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;

  position: absolute;
  top: 82%;
  left: 50%;
  transform: translate(-50%, -50%);
 
  &.gray,
  &.default {
    color: $color-p-white-50A;
  }

  &.white {
    color: $color-p-black-50A;
  }

  &.sm {
    padding: 3px 0 2px 0;
    font-weight: 500;
  }

  &.md {
    padding: 6px 0 2px 0;
    font-weight: 400;
  }

  &.lg {
    padding: 15px 0 2px 0;
    font-weight: 400;
  }
}

.colon {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  color: $color-p-white-50A;
  animation: tick 1s steps(1, end) 0s infinite;

  &.gray {
    color: $color-p-white-50A;
  }

  &.white {
    color: $color-p-black-50A;
  }

  &.sm {
    font-size: 24px;
    line-height: 1.2;
  }

  &.md {
    font-size: 40px;
    line-height: 1.1;
  }

  &.lg {
    font-size: 40px;
    line-height: 1.1;
  }
}

@keyframes tick {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.skeletonBox.white {
  max-width: 162px;
  width: 100%;
  span {
     background: linear-gradient(90deg, #d5d5d5 4.69%, #a1a1a1 100%);
     @include skeleton_animation;
  }
}
