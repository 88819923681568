@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

@mixin skeleton_animation {
  background-size: 400% 400%;
  animation: skeleton_animate 2s linear infinite;
  -webkit-animation: skeleton_animate 2s linear infinite;
}

@keyframes skeleton_animate {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.container {
  padding-right: 9px;

  span {
    height: 40px;
    display: block;
    margin: 16px 0 0 4px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);
    @include skeleton_animation;
  }
}
