@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  display: flex;
  padding: 0 12px;
  flex-direction: column;
  background-color: $color-gray-900;
  transition: width 0.3s ease;
  z-index: 100;
  /* fixed */
  top: 0;
  height: 100vh;
  position: fixed;
  will-change: width;
  @include custom-scroll;

  &:not(.collapsed) {
    width: 240px;
    flex: 0 0 240px;
  }

  &.collapsed {
    width: 64px;
    flex: 0 0 64px;
  }

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.safe-space {
  width: 240px;
}

.safe-space-collapsed {
  width: 65px;
}

.logo {
  top: 0;
  z-index: 1;
  position: sticky;
  padding-top: 32px;
  background-color: $color-gray-900 !important;

  @media (max-height: 650px) {
    padding-top: 24px;
  }
}

.content {
  flex: 1;
  height: 460px;
  display: flex;
  flex-direction: column;
}

.nav {
  margin-top: 30px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.setlists {
  flex: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 32px;

  // treatment for scrollbar
  width: calc(100% + 12px);

  > div > div:nth-child(1) {
    width: calc(100% - 12px);
  }
}

.setlistCollapsed {
  display: flex;
  padding-top: 24px;
  align-items: flex-start;
  border-top: 1px solid $color-p-white-20A;
}

.button {
  padding: 8px !important;
  color: $color-gray-60 !important;
}

.referral {
  margin: 4px 0;
}

.user {
  bottom: 0;
  position: sticky;
  background-color: $color-gray-900;

  .loading {
    padding: 12px 0;
    margin-left: 12px;
  }

  &.collapsed {
   width: 65px;
    .loading {
      left: -13px;
      position: relative;
    }
  }

  &.extraHeight {
    padding-top: 230px;
  }
}

.itemButton {
  &:hover {
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
}
