@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  padding: 16px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: $color-p-white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4);

  // fixed
  z-index: 1;
  position: fixed;
  width: max-content;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: $grid-breakpoint-small-max) {
    width: 92vw;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    max-width: 80vw;
  }

  &.hide {
    display: none;
  }
}

.text {
  @include font-defaults-new;
  font-size: 14px;
  line-height: 18px;
  color: $color-p-black;

  flex: 1;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  @media (max-width: $grid-breakpoint-small-max) {
    text-align: left;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 0 50px;
  }

  a {
    color: currentColor;
    text-decoration: underline;
  }

  p {
    margin-left: 5px;

    @media (max-width: $grid-breakpoint-small-max) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  strong {
    font-weight: 500;
  }

  u {
    cursor: pointer;
  }
}

.button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}
