@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-DemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-Medium.otf');
  font-weight: 550;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-Normal.otf');
  font-weight: 400;
}

$font-family: 'Articulat', helvetica, arial, sans-serif;

@mixin font-defaults-new {
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  font-family: $font-family;
}

@mixin font-defaults-new-normal {
  font-weight: 400;
}

@mixin font-defaults-new-medium {
  font-weight: 550;
}

@mixin font-defaults-new-demibold {
  font-weight: 600;
}
