@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.modal {
  max-width: 465px;
  border-radius: 8px;
  background-color: $color-gray-200;
}

.title {
  @include font-defaults-new;
  font-size: 18px;
  line-height: 20px;
  padding: 0 24px 24px;
}

.content {
  display: flex;
  flex-direction: row;
  padding: 0 24px 24px 24px;
}

.list {
  flex: 1;
}

.itemList {
  @include font-defaults-new;
  color: $color-gray-30;
  line-height: 20px;

  display: flex;
  margin-bottom: 12px;
}

.icon {
  margin-right: 14px;
  color: $color-gray-200;
}

.apps {
  gap: 10px;
  width: 35%;
  display: flex;
  flex-direction: column;

  a {
    height: 44px;
  }
}

.qrCode {
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 8px;
}

.desktopApp {
  margin: 0 24px 24px 24px;
  & .dowloadDesktopApp {
    padding: 4px 15px;
    width: max-content;
    line-height: 24px;
  }
}

.getDesktopApp {
  @include font-defaults-new;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 16px;
}

.highlight {
  color: $color-mint-500;
}
