@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  display: flex;
  position: relative;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;

  &.badge {
    &::after {
      top: 0;
      right: 0;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      border: 2px solid $color-gray-900;
      background-color: $color-acqua-500;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: $color-p-white;
  background-color: $color-gray-90;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
