@import '../../styles/variables.scss';
@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';

.button {
  @include font-defaults-new;

  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  outline: none;
  display: flex;
  cursor: pointer;
  font-weight: 600;
  align-items: center;
  letter-spacing: 0.02em;
  justify-content: center;

  /**
   * Radius
   */
  &.radius1 {
    border-radius: 8px;
  }

  &.radius2 {
    border-radius: 12px;
  }

  &.radius3 {
    border-radius: 1000px;
  }

  /**
   * Size
   */
  &.large {
    padding: 19px 24px;
    font-size: rem(18px);
  }

  &.long {
    padding: 4px 40px;
    font-size: 14px;
  }

  &.medium {
    padding: 16px 24px;
    font-size: rem(16px);
  }

  &.small {
    line-height: 1.5;
    padding: 11px 20px;
    font-size: rem(14px);
  }

  /**
   * State
   */
  &.disabled {
    cursor: auto;
    opacity: 0.28;
  }

  /**
   * Color Primary
   */
  &.primary.disabled {
    color: $color-p-black;
    background-color: $color-acqua-500;
  }

  &.primary:not(.transparent):not(.disabled) {
    color: $color-p-black;
    background-color: $color-acqua-500;

    &:hover {
      background-color: $color-acqua-100;
    }

    &:focus {
      border-color: $color-acqua-100;
      background-color: $color-acqua-500;
    }

    &:active {
      background-color: $color-acqua-700;
      border-color: $color-acqua-700;
    }
  }

  &.primary.transparent.disabled {
    color: $color-acqua-500;
    background-color: transparent;

    &.stroke {
      border-color: $color-gray-80;
    }
  }

  &.primary.transparent:not(.disabled) {
    color: $color-acqua-500;
    background-color: transparent;

    &.stroke {
      border-color: $color-gray-80;
    }

    &:hover {
      color: $color-acqua-100;

      &.stroke {
        border-color: $color-gray-30;
      }
    }

    &:focus {
      color: $color-acqua-500;
      border-color: $color-gray-30;
    }

    &:active {
      border-color: transparent;
      color: $color-acqua-700;

      &.stroke {
        background-color: $color-gray-900;
      }
    }
  }

  /**
   * Color Light
   */
  &.light.disabled {
    color: $color-p-black;
    background-color: $color-p-white;
  }

  &.light:not(.transparent):not(.disabled) {
    color: $color-p-black;
    background-color: $color-p-white;

    &:hover {
      background-color: rgba(255, 255, 255, 0.88);
    }

    &:focus {
      border-color: $color-gray-30;
      background-color: $color-p-white;
    }

    &:active {
      background-color: $color-gray-30;
    }
  }

  &.light.transparent.disabled {
    color: $color-p-white;
    background-color: transparent;

    &.stroke {
      border-color: $color-gray-80;
    }
  }

  &.light.transparent:not(.disabled) {
    color: $color-p-white;
    background-color: transparent;

    &.stroke {
      border-color: $color-gray-80;
    }

    &:hover {
      color: $color-gray-30;

      &.stroke {
        border-color: $color-gray-30;
      }
    }

    &:focus {
      color: $color-p-white;
      border-color: $color-gray-30;
    }

    &:active {
      border-color: transparent;
      color: $color-gray-80;

      &.stroke {
        color: $color-gray-30;
        background-color: $color-gray-900;
      }
    }
  }

  /**
   * Color Dark
   */
  &.dark.disabled {
    color: $color-p-white;
    background-color: $color-p-black;
  }

  &.dark:not(.transparent):not(.disabled) {
    color: $color-p-white;
    background-color: $color-p-black;

    &:hover {
      background-color: $color-gray-500;
    }

    &:focus {
      border-color: $color-p-white;
      background-color: $color-p-black;
    }

    &:active {
      border-color: $color-gray-900;
      background-color: $color-gray-900;
    }
  }

  &.dark.transparent.disabled {
    color: $color-p-black;
    background-color: transparent;

    &.stroke {
      border-color: $color-gray-700;
    }
  }

  &.dark.transparent:not(.disabled) {
    color: $color-p-black;
    background-color: transparent;

    &.stroke {
      border-color: $color-gray-700;
    }

    &:hover {
      color: $color-gray-300;

      &.stroke {
        color: $color-gray-200;
        border-color: $color-gray-500;
      }
    }

    &:focus {
      color: $color-p-black;
      border-color: $color-p-white;
    }

    &:active {
      border-color: transparent;
      color: $color-gray-80;

      &.stroke {
        color: $color-gray-700;
        border-color: $color-gray-500;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  /**
   * Color Danger
   */
  &.danger.disabled {
    color: $color-p-black;
    background-color: $color-s-error;
  }

  &.danger:not(.transparent):not(.disabled) {
    color: $color-p-black;
    background-color: $color-s-error;

    &:hover {
      opacity: 0.7;
      background-color: $color-s-error;
    }

    &:focus {
      border-color: $color-p-white;
      background-color: $color-s-error;
    }

    &:active {
      opacity: 0.5;
      border-color: transparent;
      background-color: $color-s-error;
    }
  }

  &.danger.transparent.disabled {
    color: $color-s-error;
    background-color: transparent;

    &.stroke {
      border-color: $color-s-error;
    }
  }

  &.danger.transparent:not(.disabled) {
    color: $color-s-error;
    background-color: transparent;

    &.stroke {
      border-color: $color-s-error;
    }

    &:hover {
      opacity: 0.7;
      color: $color-s-error;

      &.stroke {
        opacity: 0.7;
        border-color: $color-s-error;
      }
    }

    &:focus {
      color: $color-s-error;
      border-color: $color-s-error;

      &.stroke {
        border-color: $color-gray-30;
      }
    }

    &:active {
      opacity: 0.5;
      color: $color-s-error;
      border-color: transparent;
      background: rgba(255, 97, 61, 0.12);

      &.stroke {
        opacity: 0.5;
        border-color: $color-s-error;
        background: rgba(255, 97, 61, 0.12);
      }
    }
  }

  /**
   * Color Quiet
   */
  &.quiet {
    color: $color-p-white;
    background-color: $color-p-white-10A;

    &.disabled {
      opacity: 0.7;
    }

    &:not(.disabled) {
      &:hover {
        opacity: 0.7;
        background-color: $color-p-white-30A;
      }

      &:focus {
        border-color: $color-p-white;
        background-color: $color-p-white-30A;
      }

      &:active {
        opacity: 0.5;
        border-color: transparent;
        background-color: $color-p-white-30A;
      }
    }
  }
}

.icon {
  &.withTitle {
    margin-right: 10px;
  }
}

.iconRight {
  &.withTitle {
    margin-left: 10px;
  }
}
