@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';

.alert {
  display: flex;
  padding: 10px;
  flex-direction: row;
  border-radius: 12px;
  width: 100%;
  max-width: 420px;
  transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out;

  &.default {
    background: $color-p-white;
  }

  &.error {
    background: $color-s-error;
  }

  &.warning {
    background: $color-s-warning;
  }

  &.success {
    background: $color-s-success;
  }

  &.aurora {
    background: $color-g-aurora;
  }

  &.sunrise {
    background: $color-g-sunrise;
  }

  &.sunset {
    background: $color-g-sunset;
  }

  &.nightfall {
    background: $color-g-nightfall;
  }

  &.spotlight {
    color: white;
    background: $color-g-spotlight;
  }

  &.acqua {
    background: $color-acqua-500;
  }
}

.iconWrapper {
  margin-right: 8px;
}

.container {
  flex: 1;
  gap: 6px;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;

  &.column {
    align-items: center;
    flex-direction: row;

    .action p {
      @include force-one-line;
    }
  }
}

.content {
  gap: 4px;
  display: flex;
  flex-direction: column;
}

.title {
  @include font-defaults-new;
  font-size: 14px;
  line-height: 14px;
  color: $color-p-black;
}

.description {
  @include font-defaults-new;
  font-size: 14px;
  line-height: 18px;
  color: $color-p-black;
}

.link {
  color: $color-acqua-500;
}

.action {
  width: fit-content;
  padding: 3px 8px !important;
}

.close {
  border: 0;
  padding: 0;
  opacity: 0.5;
  display: flex;
  cursor: pointer;
  margin-left: 8px;
  height: fit-content;
  background-color: transparent;

  &:hover {
    opacity: 0.8;
  }

  .iconDark {
    color: $color-p-black;
  }

  .iconWhite {
    color: $color-p-white;
  }
}
