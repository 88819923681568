@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

@mixin skeleton_animation {
  background-size: 400% 400%;
  animation: skeleton_animate 2s linear infinite;
  -webkit-animation: skeleton_animate 2s linear infinite;
}

@keyframes skeleton_animate {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.title {
  display: block;
  width: 70px;
  height: 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  background: linear-gradient(
    90deg,
    $color-p-white-10A 4%,
    $color-p-white-5A 100%
  );
  @include skeleton_animation;
  opacity: 0.4;
  margin-left: auto;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 8px 8px 14px;
    border-radius: 8px;
    width: 100%;
    opacity: 0.4;

    &,
    .url,
    .copy {
      background: linear-gradient(
        90deg,
        $color-p-white-10A 4%,
        $color-p-white-5A 100%
      );
      @include skeleton_animation;
    }

    .url,
    .copy {
      display: block;
      border-radius: 4px;
    }

    .url {
      width: 100%;
      height: 24px;
    }

    .copy {
      width: 80px;
      height: 34px;
    }
  }
}
