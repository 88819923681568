@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';

.button {
  @include font-defaults-new;

  width: 100%;
  display: flex;
  align-items: center;
  padding: 7px 10px 7px 13px;
  border-width: 0;
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: transparent;
  background-color: transparent;

  &:not(.light) {
    color: $color-gray-40;
  }

  &.light {
    color: $color-p-white;
  }

  &.disabled {
    opacity: 0.28;
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      background-color: rgb(0, 0, 0, 0.16);

      .oneLine::after {
        background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 0) 0%,
          rgba(14, 14, 14, 1) 90%
        );
      }
    }
  }

  &.active {
    font-weight: 500;
    color: $color-p-white;
    border-left-color: $color-p-white;
  }
}

.label {
  text-align: left;
  font-size: rem(16px);

  &.oneLine {
    flex: 1;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    &::after {
      content: '';
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      position: absolute;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(17, 17, 17, 1) 90%
      );
    }
  }
}

.icon {
  margin-right: 12px;
}

.beta {
  @include font-defaults-new;

  top: -1px;
  padding: 1px 2px;
  margin-left: 3px;
  border-radius: 2px;
  position: relative;
  color: $color-p-black;
  background-color: $color-p-white-30A;
  font-weight: 500;
  font-size: rem(10px);
  line-height: rem(12px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.container {
  position: relative;
}

.tooltip {
  z-index: 9999;
  color: $color-p-black;
  transform: translate(-75%, 60%);
  display: none !important;
  position: absolute !important;

  p {
    margin: -3px;
    overflow: hidden;
    font-weight: lighter;
    font-size: 13.7px;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.iconTooltip {
  @include font-defaults-new;
  letter-spacing: 0.02em;
  width: max-content;

  @media (min-width: $grid-breakpoint-medium-min) {
    margin: 0 auto;
    text-align: center;

    &:hover {
      ~ .tooltip {
        display: flex !important;
      }
    }
  }
}
