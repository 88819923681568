@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  gap: 12px;
  border: 0;
  padding: 8px;
  display: flex;
  cursor: pointer;
  text-align: left;
  border-radius: 8px;
  align-items: center;
  color: $color-gray-30;
  background-color: $color-gray-700;

  @include font-defaults-new;
  font-size: 14px;
  line-height: 18px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
}

.image {
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  position: relative;
}
