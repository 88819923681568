@import '../../../styles/typography.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/utilities.scss';

.close {
  top: 16px;
  right: 16px;
  position: absolute;

  padding: 0;
  border: none;
  cursor: pointer;
  color: $color-p-white;
  background-color: transparent;

  @media (max-width: $grid-breakpoint-medium-max) {
    top: 8px;
    right: 8px;
  }
}

.container {
  max-width: 526px;
  overflow: hidden;
  border-radius: 16px;
  background-color: $color-gray-700;
}

.content {
  padding: 36px 32px 16px 32px;

  @media (max-width: $grid-breakpoint-medium-max) {
    padding: 26px 20px 12px 20px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 90px 28px 90px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('https://storage.googleapis.com/scala-web/assets/images/group-plan-banner-background@2x.png');

  @media (max-width: $grid-breakpoint-medium-max) {
    padding: 10px 17px;
    background-image: url('https://storage.googleapis.com/scala-web/assets/images/group-plan-banner-background-mobile.png');
  }

  .badge {
    @include font-defaults-new;
    color: $color-p-black;
    font-size: 12px;
    font-weight: 600;

    padding: 2px 12px;
    width: max-content;
    border-radius: 50px;
    background-color: $color-mint-500;
  }

  .title {
    @include font-defaults-new;
    color: $color-p-white;
    font-size: 24px;
    font-weight: 600;
    margin-top: 8px;

    @media (max-width: $grid-breakpoint-medium-max) {
      font-size: 16px;
    }
  }

  .description {
    @include font-defaults-new;
    color: $color-p-white-70A;
    font-size: 18px;
    margin-top: 16px;

    @media (max-width: $grid-breakpoint-medium-max) {
      font-size: 14px;
    }
  }
}

.list {
  gap: 12px;
  display: flex;
  position: relative;
  flex-direction: column;

  &::after {
    top: 0px;
    z-index: 1;
    left: 20px;
    width: 1px;
    content: '';
    height: 100%;
    position: absolute;
    background-color: $color-gray-90;
  }
}

.disclaimer {
  @include font-defaults-new;
  font-size: 12px;
  margin-top: 19px;
  text-align: center;
  padding: 0 36px;

  @media (max-width: $grid-breakpoint-medium-max) {
    padding: 0;
  }
}

.button {
  min-width: 212px;
  margin: 32px auto 0 auto;
  padding: 6px 20px !important;

  &[disabled] {
    cursor: wait;
  }
}
