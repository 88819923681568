@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/variables.scss';
@import 'scala/src/styles/utilities.scss';

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  background-color: transparent;
  color: $color-p-white;
  border: none;
}

.container {
  @include font-defaults-new;
  background-color: $color-gray-700;
  width: 328px;
  overflow: auto;
  line-height: rem(16.8px);
  border-radius: 16px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 100%;
    min-height: 120px;
  }

  .modalBody {
    padding: 24px;
    @include font-defaults-new-normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    text-align: center;
    font-size: 14px;

    .modalText {
      display: flex;
      flex-direction: column;
      gap: 16px;
      font-style: normal;

      .title,
      .highlightDescription {
        font-size: 18px;
        font-weight: 550;
      }

      .description {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .cta {
    @include font-defaults-new;
    width: 100%;
    font-size: 14px;
    padding: 6px 12px;
    height: 36px;
    font-weight: 600;
    line-height: 0;
  }

  .highlightTitle {
    color: $color-mint-500;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
}
