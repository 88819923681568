@import '../../../styles/typography.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/utilities.scss';

.modal {
  width: 480px;
  min-height: 240px;
  position: relative;
  border-radius: 15px;
  background-color: $color-gray-700;
}

.root {
  @include font-defaults-new;
  display: flex;
  flex-direction: column;
  padding: 24px;
  line-height: 1.5;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;

    .title {
      font-size: 20px;
      line-height: 1.08;
      color: $color-p-white;
    }

    .closeButton {
      margin: -8px -6px 0 0;
      padding: 8px;
      border: 0;
    }
  }

  .divider {
    width: calc(100% + 8px);
    height: 1.5px;
    margin: 0 0 16px -4px;
    border-color: $color-gray-400;
  }
}
