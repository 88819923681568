@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.modal {
  width: 100%;
  min-width: 328px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  background-color: #262626;
  box-shadow: 0px 0px 12px rgba(106, 106, 106, 0.4);

  button {
    z-index: 2;
    position: relative;
  }
}

.content {
  @include font-defaults-new;
  display: flex;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  &.background {
    &:after {
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      content: '';
      height: 160px;
      position: absolute;
      background: linear-gradient(180deg, #013641 0%, #011a1f 100%);
    }
  }
}

.badge {
  left: 0;
  top: 20px;
  z-index: 2;
  position: absolute;
}

.image {
  z-index: 1;
  top: -30px;
  margin-bottom: 6px;
  position: relative;
}

.title {
  @include font-defaults-new;
  font-weight: 600;
  font-size: 20px;

  max-width: 280px;
}

.info {
  @include font-defaults-new;
  font-size: 14px;
  color: $color-gray-30;

  margin-top: 8px;
  max-width: 280px;

  a {
    color: $color-acqua-500;
  }
}

.disclaimer {
  @include font-defaults-new;
  font-size: 14px;
  margin-top: 12px;
  color: $color-mint-500;
}

.actions {
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;

  .button {
    width: 100%;

    p:first-letter {
      text-transform: uppercase;
    }

    &.cancel {
      margin-top: 12px;
    }
  }
}
