@import '../../styles/variables.scss';

.dropMenu {
  z-index: 19;
  padding: 6px 0;
  overflow: hidden;
  min-width: 240px;
  max-width: 320px;
  border-radius: 8px;
  background-color: $color-gray-300;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
}
