$animationDuration: 5000ms;
$animationIterationCount: infinite;
$animationTimingFunction: linear;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
