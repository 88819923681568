@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
@import '../../styles/variables.scss';

.container {
  bottom: 0;
  z-index: 99;
  position: fixed;
  padding-bottom: 5px;
  border-radius: 8px 8px 0px 0px;
  background-color: $color-gray-300;

  @media (max-width: $grid-breakpoint-small-max) {
    left: 16px;
    right: 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    right: 32px;
    width: 415px;
  }
}

.button {
  border: 0;
  margin: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: $color-p-white;
  background: transparent;
}

.header {
  display: flex;
  max-height: 50px;
  padding-left: 16px;
  transition: max-height 0.2s linear;
  -o-transition: max-height 0.2s linear;
  -moz-transition: max-height 0.2s linear;
  -webkit-transition: max-height 0.2s linear;

  &:not(.opened) {
    max-height: 28px;
  }
}

.title {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.label {
  @include font-defaults-new;
  line-height: 20px;
  text-align: left;
  flex: 1;
}

.iconArrow {
  padding: 10px 0 10px 0;
  transform: rotate(180deg);

  &.invert {
    transform: rotate(0deg);
  }
}

.iconZip {
  margin-right: 20px;

  &.hide {
    display: none;
  }
}

.iconClose {
  padding: 10px 16px 10px 15px;
}

.content {
  max-height: 0px;
  overflow-y: auto;
  transition: max-height 0.2s linear;
  -o-transition: max-height 0.2s linear;
  -moz-transition: max-height 0.2s linear;
  -webkit-transition: max-height 0.2s linear;

  &.show {
    max-height: 150px;
    padding-bottom: 5px;
  }
}
