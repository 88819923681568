@import '../../styles/variables.scss';
@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';

.avatar {
  @include font-defaults-new;

  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $color-gray-90;

  @media (max-width: $grid-breakpoint-small-max) {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 44px;
    height: 44px;
    flex: 0 0 44px;

    &.active {
      border: 1px solid $color-p-white;
    }
  }
}

.initials {
  font-size: 16px;
  color: $color-p-white;
  text-transform: uppercase;
}

.icon {
  color: $color-p-white;
}

.image {
  @media (max-width: $grid-breakpoint-small-max) {
    width: 40px;
    height: 40px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 44px;
    height: 44px;
  }
}
