@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  gap: 12px;
  height: 70px;
  display: flex;
  position: relative;
  align-items: center;
}

.title {
  @include font-defaults-new;
  font-size: 14px;
  text-align: right;
  color: $color-p-white;
}

.iconContainer {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;
  flex: 0 0 44px;
  border-radius: 50%;
  background-color: $color-gray-300;
}

.icon {
  top: -2px;
  position: relative;
  color: $color-p-white;
}
