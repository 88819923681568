@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  display: flex;
  padding: 12px 0;
  position: relative;
  flex-direction: column;
}

.button {
  flex: 1;
  gap: 12px;
  border: 0;
  width: 100%;
  display: flex;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  background-color: transparent;

  &:hover,
  &.active {
    background: $color-p-white-5A;
  }

  &.small {
    padding: 12px 0;
  }
}

.user {
  gap: 2px;
  flex: 1;
  display: flex;
  text-align: left;
  flex-direction: column;
}

.title {
  @include font-defaults-new;
  color: $color-p-white;

  // truncate string with ellipsis
  width: 134px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.plan {
  @include font-defaults-new;
  font-size: 12px;

  &.isStarter {
    color: $color-acqua-500;
  }

  &.isPremium {
    color: $color-mint-500;
  }

  &.isPro {
    color: $color-gray-40;
  }
}

.buttonPremium {
  color: $color-mint-500 !important;
}

.drop {
  left: 0;
  top: -100vh;
  position: absolute;

  display: flex;
  min-width: 216px;
  max-width: 216px;
  flex-direction: column;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;

  &:not(.show) {
    opacity: 0;
    visibility: hidden;
  }

  &.show {
    opacity: 1;
    top: auto;
    bottom: 95%;
  }
}
