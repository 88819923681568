@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @include font-defaults-new;
  padding: 0 13px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  color: $color-gray-10;
}

.playlistContainer {
  padding: 8px 22px 8px 0px;
  margin-right: 12px;
  &:hover {
    border-radius: 8px;
    background: $color-p-white-5A;
    cursor: pointer;
  }
}

.button {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 12px;
  border: 0;
  display: flex;
  align-items: center;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
}

.icon {
  padding: 8px;
  color: $color-acqua-500;
  border-radius: 6px;
  background-color: $color-p-white-10A;

  &.icon-playlist {
    background: $color-p-white-10A;
    padding: 8px;
    width: 40px;
    height: 40px;
    color: $color-gray-30;
  }
}

.text {
  @include font-defaults-new;
  font-size: 14px;
  font-weight: 500;
  color: $color-acqua-500;
  letter-spacing: 0.02em;
  width: 120px;
  text-align: start;
}

.list {
  flex: 1;
  display: flex;
  margin-top: 10px;
  padding-bottom: 100px;
  flex-direction: column;
  @include custom-scroll-hover;
}
.divider {
  width: calc(100% + 8px);
  height: 1.5px;
  margin: 0px 0 16px 0px;
  border-color: $color-gray-400;
}
