@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  cursor: pointer;
  border-radius: 10px;
  background: $color-gray-400;
  left: 0;
  bottom: 88px;
  width: 100%;
  position: absolute;
}

.containerContent {
  gap: 10px;
  display: flex;
  padding: 12px;
  padding-top: 16px;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
}

.closeButton {
  top: 10px;
  right: 5px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  color: $color-p-white;
  border: 0;
  outline: none;
  background-color: transparent;
}

.videoBackground {
  width: 100%;
  height: 105px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.image {
  height: 105px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.labelContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 18px;
  color: $color-p-white;
  font-weight: 600;
}

.description {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 12px;
  color: $color-gray-40;
}

.badge {
  background-color: #0affa7;
  padding: 2px 12px;
  border-radius: 50px;
  color: $color-p-black;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  top: -10px;
  left: 8px;
}
