@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  padding: 8px 22px 8px 0px;
  margin-right: 12px;
  border-radius: 8px;
  position: relative;

  &:hover,
  &.active {
    background: rgba(255, 255, 255, 0.05);
    cursor: pointer;
    .more {
      display: flex !important;
    }
  }
}

.item {

  > div > button {
    padding-left: 0 !important;
    border-left-color: transparent !important;
  }
  display: flex;
  align-items: center;
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-left: 12px;
}

.actions {
  width: 8%;

  > div > div > button {
    padding: 0 !important;
  }
}

.more {
  display: none;
  position: relative;
  right: 8px;

  > button > span > svg{
    color: $color-gray-90;
    width: 24px;
    height: 24px;
  }
}

.icon {
  border-radius: 6px;
  
    &.icon-playlist {
      background: $color-p-white-10A;
      padding: 8px;
      width: 40px;
      height: 40px;
    }
}

.containerIcon {
  display: flex;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  overflow: hidden;
  border-radius: 6px;
}

.quadGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}

.default {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}