// @import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.checkbox {
  width: 42px;
  height: 24px;
  flex: 0 0 42px;
  margin-left: 12px;
  position: relative;
  border-radius: 100px;
  background-color: $color-gray-80;

  &.active {
    background-color: $color-acqua-500;
  }

  &::before {
    content: '';
    z-index: 1;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    background-color: $color-p-white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
  }

  &.active {
    &::before {
      left: 20px;
    }
  }
}
