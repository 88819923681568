@import '../../../styles/typography.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/utilities.scss';

.title {
  font-size: rem(12px);
  color: $color-p-white-50A;
  text-align: right;
  margin-bottom: 8px;
}

.info {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .icon {
    margin: 20px 0 12px;
  }

  .description {
    color: $color-acqua-500;
    margin-bottom: 4px;
  }

  .tip {
    font-size: rem(12px);
    max-width: 340px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include font-defaults-new;

  .item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 8px 8px 14px;
    border-radius: 8px;
    background-color: $color-p-white-10A;
    transition: background-color 0.4s ease-in-out;

    &:focus-within {
      color: $color-acqua-100;
      background-color: $color-p-white-20A;
    }
  }

  .input {
    color: $color-p-white;
    width: 100%;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    background: transparent;
    border: none;
    outline: none;
    height: 32px;

    &:focus {
      outline: none;
    }
  }

  .copy {
    padding: 4px 12px;
    flex-shrink: 0;
  }
}
