@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
.overlay {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 200ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .overlayText {
    position: absolute;
    top: 25px;
    left: 265px;
    transform: translateX(0px);

    @media (max-width: 1290px) {
      left: 90px;
      transform: translateX(0px);
    }
    @media (max-width: 945px) {
      left: 50px;
      transform: translateX(0px);
    }
  }
}

.modal {
  .container {
    @include font-defaults-new;

    font-size: 32px;
    line-height: 40px;
    color: white;

    .closeButton {
      z-index: 1;
      position: relative;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-right: 18px;
      margin: 0 0 0 auto;
      border-width: 2px;
      border-style: dashed;
      border-color: transparent;
    }

    .closeIcon {
      color: white;
    }
  }
}
