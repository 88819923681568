/**
 * Variables
 */
$grid-content-height: 100vh;
$max-width-content: 1140px;
$grid-breakpoint-small-max-pricing-page: 800px;

/**
 * Breakpoints
 */
$grid-breakpoint-small-min: 320px;
$grid-breakpoint-small-max: 639px;
$grid-breakpoint-medium-min: 640px;
$grid-breakpoint-medium-max: 959px;
$grid-breakpoint-large-min: 960px;
$grid-breakpoint-large-max: 1440px;

/**
 * Config Grid System
 */
$theflexgrid-gutter: 28px !default;

$theflexgrid-breakpoint-small: '(max-width: 639px)' !default;
$theflexgrid-breakpoint-medium: '(min-width: 640px) and (max-width: 959px)' !default;
$theflexgrid-breakpoint-large: '(min-width: 960px)' !default;

/**
 * Primary
 */
$color-gray-900: #111; // color-p-night
$color-gray-800: #171717;
$color-gray-700: #1d1d1d; // color-p-ink
$color-gray-600: #212121;
$color-gray-500: #262626; // color-p-oil
$color-gray-400: #2d2d2d;
$color-gray-300: #333; // color-p-charcoal
$color-gray-200: #3b3b3b; // color-p-graphite
$color-gray-100: #474747;
$color-gray-90: #545454;
$color-gray-80: #666; // color-p-smoke
$color-gray-70: #737373;
$color-gray-60: #858585;
$color-gray-50: #949494;
$color-gray-40: #a8a8a8;
$color-gray-30: #bfbfbf; // color-p-cloud
$color-gray-20: #d1d1d1;
$color-gray-10: #e8e8e8;

$color-acqua-900: #0086a0;
$color-acqua-700: #00b4cc; // color-p-ocean
$color-acqua-500: #00dae8; // color-p-acqua
$color-acqua-300: #41efff;
$color-acqua-100: #98fcff;

$color-turquoise-900: #00ad86;
$color-turquoise-700: #0bd4a7;
$color-turquoise-500: #31eac0; // color-p-turquoise
$color-turquoise-300: #42ffd4;
$color-turquoise-100: #75ffe0;

$color-mint-900: #00b272;
$color-mint-700: #00db8c;
$color-mint-500: #0affa7; // color-p-mint
$color-mint-300: #69ffc9;
$color-mint-100: #9fffdd;

/**
 * Primary + Opacity
 */
$color-p-black: #000;
$color-p-black-5A: rgb(0, 0, 0, 0.05);
$color-p-black-10A: rgb(0, 0, 0, 0.1);
$color-p-black-15A: rgb(0, 0, 0, 0.15);
$color-p-black-20A: rgb(0, 0, 0, 0.2);
$color-p-black-25A: rgb(0, 0, 0, 0.25);
$color-p-black-30A: rgb(0, 0, 0, 0.3);
$color-p-black-35A: rgb(0, 0, 0, 0.35);
$color-p-black-40A: rgb(0, 0, 0, 0.4);
$color-p-black-45A: rgb(0, 0, 0, 0.45);
$color-p-black-50A: rgb(0, 0, 0, 0.5);
$color-p-black-60A: rgb(0, 0, 0, 0.6);
$color-p-black-70A: rgb(0, 0, 0, 0.7);
$color-p-black-80A: rgb(0, 0, 0, 0.8);
$color-p-black-90A: rgb(0, 0, 0, 0.9);

$color-p-white: #fff;
$color-p-white-5A: rgb(255, 255, 255, 0.05);
$color-p-white-10A: rgb(255, 255, 255, 0.1);
$color-p-white-15A: rgb(255, 255, 255, 0.15);
$color-p-white-20A: rgb(255, 255, 255, 0.2);
$color-p-white-25A: rgb(255, 255, 255, 0.25);
$color-p-white-30A: rgb(255, 255, 255, 0.3);
$color-p-white-35A: rgb(255, 255, 255, 0.35);
$color-p-white-40A: rgb(255, 255, 255, 0.4);
$color-p-white-45A: rgb(255, 255, 255, 0.45);
$color-p-white-50A: rgb(255, 255, 255, 0.5);
$color-p-white-60A: rgb(255, 255, 255, 0.6);
$color-p-white-70A: rgb(255, 255, 255, 0.7);
$color-p-white-80A: rgb(255, 255, 255, 0.8);
$color-p-white-90A: rgb(255, 255, 255, 0.9);

/**
  * Secondary
  */
$color-s-watermelon: #fe6577;
$color-s-ultraviolet: #884fff;
$color-s-starship: #f4ed42;
$color-s-malibu: #76adff;

/**
  * System Colors
  */
$color-s-error: #ff613d;
$color-s-warning: #ffd200;
$color-s-success: #48dc6b;

/**
  * Gradients
  */
$color-g-aurora: linear-gradient(227.01deg, #63fb97 15.36%, #00dae8 86.7%);
$color-g-sunrise: linear-gradient(227.01deg, #f4ed42 15.36%, #fe6577 86.7%);
$color-g-sunset: linear-gradient(227.01deg, #fe6577 15.36%, #884fff 86.7%);
$color-g-nightfall: linear-gradient(227.01deg, #76adff 15.36%, #884fff 86.7%);
$color-g-spotlight: linear-gradient(
  35.9deg,
  #000 -6.17%,
  #000 67.99%,
  #1c3047 99.29%
);
