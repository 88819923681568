@import '../../styles/typography.scss';
@import '../../styles/utilities.scss';
@import '../../styles/variables.scss';

.container {
  @include font-defaults-new;
  font-size: 14px;
  line-height: 18px;

  height: 45px;
  display: flex;
  padding-left: 16px;
  align-items: center;
  justify-content: space-between;

  &.success {
    color: $color-mint-500;
  }

  &.failed {
    color: $color-s-error;
  }

  @media (max-width: $grid-breakpoint-small-max) {
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    .buttonCancel {
      display: none;
    }

    &.failed:hover,
    &.loading:hover {
      background: $color-gray-200;

      .failed,
      .iconLoading {
        display: none;
      }

      .buttonCancel {
        display: flex;
      }
    }
  }
}

.state {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 16px;
}

.icon {
  padding-left: 10px;
}

.buttonCancel {
  border: 0;
  cursor: pointer;
  padding-left: 15px;
  margin-right: -6px;
  color: $color-p-white;
  background: transparent;
}

.title {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
