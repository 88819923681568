@import '../../styles/animation.scss';

.icon {
  display: flex;

  &.animate {
    animation-duration: $animationDuration;
    animation-iteration-count: $animationIterationCount;
    animation-timing-function: $animationTimingFunction;
  }

  &.spin {
    animation-name: spin;
  }
}
