@import '../../../styles/typography.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/utilities.scss';

.item {
  gap: 24px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.icon {
  top: -2px;
  right: -12px;
  position: absolute;
  color: $color-gray-700;
}

.round {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: $color-gray-90;
  background-color: $color-gray-700;

  &.active {
    color: $color-mint-500;
    border-color: $color-mint-500;
  }
}

.title {
  @include font-defaults-new;
  font-size: 18px;
  font-weight: 600;
}

.description {
  @include font-defaults-new;
  font-size: 16px;
}
