@import '../../styles/variables.scss';
@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';

.errorBar {
  @include font-defaults-new;

  display: flex;
  align-items: center;
}

.label {
  font-size: 14px;
  color: $color-p-white;
  text-align: right;
  margin-right: 12px;
  letter-spacing: 0.01em;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.iconContainer {
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $color-gray-90;

  @media (max-width: $grid-breakpoint-small-max) {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 44px;
    height: 44px;
    flex: 0 0 44px;
  }
}

.icon {
  top: -2px;
  position: relative;
  color: $color-p-white;
}
