@import '../../../styles/typography.scss';
@import '../../../styles/utilities.scss';
@import '../../../styles/variables.scss';

.container {
  cursor: pointer;
  border-radius: 10px;
  background: $color-gray-400;
  left: 0;
  bottom: 88px;
  width: 100%;
  position: absolute;
}

.containerContent {
  gap: 12px;
  display: flex;
  padding: 12px;
  padding-top: 16px;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
}

.closeButton {
  top: 8px;
  right: 5px;
  position: absolute;
  cursor: pointer;
  color: $color-p-white;
  border: 0;
  outline: none;
  background-color: transparent;
}

.image {
  height: 105px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-position: 50% 50%;
}

.labelContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 18px;
  color: $color-p-white;
  font-weight: 600;
}

.description {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  color: $color-gray-20;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.button {
  font-weight: 600;
  letter-spacing: 0;
  padding: 8px 7px !important;
  margin-top: 7px;
}
