@use 'sass:math';

/**
 * Convert px to rem
 * base: 16px
 */
@function rem($size) {
  $remSize: math.div($size, 16px);

  @return #{$remSize}rem;
}

@mixin force-word-wrap($quantity) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: $quantity;
  -webkit-line-clamp: $quantity;
  -webkit-box-orient: vertical;
}

@mixin custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: #333 #212121;

  /* For Safari and Chrome older version 121 */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #212121;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #333;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
}

@mixin custom-scroll-hover {
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* For Safari and Chrome older version 121 */
  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
    box-shadow: none;
  }

  &:hover {
    scrollbar-color: #333 #212121;

    &::-webkit-scrollbar {
      background-color: #212121;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #333;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
  }
}

@mixin force-one-line {
  overflow: hidden;
  white-space: nowrap;
}

@mixin page-without-margin {
  margin: 0;
}

@mixin page-full-content {
  max-width: 100%;
}

@mixin first-letter-uppercase {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

@mixin skeleton_animation {
  background-size: 400% 400%;
  animation: skeleton_animate 2s linear infinite;
  -webkit-animation: skeleton_animate 2s linear infinite;
}

@keyframes skeleton_animate {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes loading-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
